import React from 'react'
import PropTypes from 'prop-types'

const Tutoring = (props) => {
  const { data } = props

  return (
    <div className='content'>      
      <div>
        <div>
          <h2 className='has-text-centered has-text-weight-semibold is-size-3 mt-6'>
            Our Tutees
          </h2>
          <p className='has-text-weight-semibold is-size-5 mt-5'>Our tutees enjoy these exclusive benefits:</p>
          <ul className='is-size-5'>
            <li>Receive one-on-one tutoring from trained student volunteer tutors.</li>
            <li>Enhance proficiency in a range of subject areas such as math, English, science, and more.</li>
            <li>Tailor your own learning – you decide on the subject area for each tutoring session.</li>
            <li>Develop effective learning habits by regularly attending tutoring sessions.</li>            
            <li>Enhance social skills by participating in special social events organized by the CAPSA.</li>
            <li>Be part of a supportive community comprised of dedicated tutees, student tutors, and student leaders.</li>
          </ul>
        </div>
      </div>
      <div>
        <div className='mt-6'>
          <h2 className='has-text-centered has-text-weight-semibold is-size-3 mt-6'>
           Our Tutors
          </h2>
          <p className='has-text-weight-semibold is-size-5 mt-5'>All our tutors are eager volunteers. As a tutor, you will:</p>
          <ul className='is-size-5'>
            <li>Receive SSL hours for volunteering your time to tutor the students in need. The CAPSA is certified by MCPS to offer SSL hours.</li>
            <li>Exclusive opportunity to receive training by MCPS teaching faculty members on how to become an effective tutor.</li>
            <li>Enhance your organizational skills and leadership skills.</li>
            <li>Opportunity to participate in special social events organized by the CAPSA.</li>
            <li>Be part of a supportive community comprised of dedicated tutees, student tutors, and student leaders.</li>
            <li>Give back to the community and make a difference in other students’ lives. </li>
          </ul>
        </div>
      </div>
      <div>
        <div className='mt-6'>
          <h2 className='has-text-centered has-text-weight-semibold is-size-3 mt-6'>
            Our Coordinators
          </h2>
          <p className='has-text-weight-semibold is-size-5 mt-5'>Tutors who demonstrate enthusiasm, leadership, and reliability can become coordinators for their tutoring group. </p>
          <ul className='is-size-5'>
            <li>Only a tutor with at least 1 year service experience in CAPSA can apply.</li>
            <li>Coordinators work with tutors and tutees to manage their tutoring group.</li>
            <li>It’s a great opportunity to practice and exercise leadership.</li>
            <li>Coordinators who excel in their position can become lead coordinators!</li>
          </ul>
        </div>
      </div>
      
    </div>
  )
}

Tutoring.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      description: PropTypes.string,
      items: PropTypes.array,
    }),
  ),
}

export default Tutoring
