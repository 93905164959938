import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import TutoringPageTemplate from '../components/TutoringPageTemplate'
import Layout from '../components/Layout'

const TutoringPage = (props) => {
  const { data: { markdownRemark: { frontmatter: { title, meta_title, meta_description, tutoring } } } } = props

  return (
    <Layout>
      <TutoringPageTemplate
        title={title}
        meta_title={meta_title}
        meta_description={meta_description}
        tutoring={tutoring}
      />
    </Layout>
  )
}

TutoringPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default TutoringPage

export const tutoringPageQuery = graphql`
  query TutoringPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        tutoring {
          heading
          description
          plans {
            description
            items
            plan
          }
        }
      }
    }
  }
`
